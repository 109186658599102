<template>
  <svg width="29" height="31" viewBox="0 0 29 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1.5" y="6.5" width="23" height="23" rx="2.5" stroke="white" stroke-width="3" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21.8284 10.9403C19.1257 14.6543 16.6285 18.8817 15.1632 21.6291L13.8988 24L6 16.1592L8.8227 13.3572L12.9313 17.4356C14.4426 14.7966 16.4478 11.5671 18.5924 8.61995C20.0094 6.67269 21.5172 4.80701 23.0102 3.30725C24.4564 1.85455 26.0771 0.549462 27.7377 0L29 3.75928C28.2654 4.00234 27.1916 4.74461 25.8496 6.09258C24.5546 7.39348 23.1808 9.08178 21.8284 10.9403Z"
      fill="white"
    />
  </svg>
</template>
